body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jumbo-user-first {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none;
  font: normal 50px/1 Roboto Black, Gadget, Roboto;
  color: #1861a0;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}


.jumbo-user-second {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none;
  font: normal 30px/1 Roboto Black, Gadget, Roboto;
  color: #419a1d;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.jumbo-user-third {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none;
  font: normal 30px/1 Roboto Black, Gadget, Roboto;
  font-style: italic;
  color: #88adce;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}


.footer-text {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: none;
  font: normal 15px/1 Roboto Black, Gadget, Roboto;
  color: #000000;
  text-align: left;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-backgroundcolor { 
  background-color: black; /*#274250*/
}

.footer1-backgroundcolor {
  background-color: white; /*#3c647a*/
}

.navbar-header {
  background-color: darkred;
}

.menubar {
  background-color: lightgrey;
  /*padding: 0px;*/
}

.vr {
  border-left: 2px solid whitesmoke;
  height: 30px;
  padding: 0px;
}

.datemain-tableheader {
  background-color: white;
}

.datemain-div-1 {
  background-color: #d5d5d5;
}

.datemain-div-2 {
  background-color: #f2f2f2;
}

.datemain-div-3 {
  background-color: #f2f2f2;
}

.datemain-div-3-font {
  color: darkcyan;
}

.datemain-label-heading1 {
  padding: 0px;
  font-size: 20px;
  color: darkgreen;
}

.datemain-label-heading2 {
  padding: 0px;
  font-size: 20px;
  color: purple;
}

.menu-dropdown-button {
  background-color: #ccccc4;
  padding: 0px;
  width: 100%;
}

/* Split the screen in half */
.split {
  height: 100%;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Control the left side */
.left {
  left: 0;
  background-color: #111;
}

/* Control the right side */
.right {
  right: 0;
  background-color: red;
}

.page-container {
  height: 920px; /* or whatever height you want */
  display: flex;
  overflow: hidden;
}

.column-page1 {
  width: 20%; /* or whatever width you want */
  background-color: gray;
}

.column-page2 {
  width: 80%; /* or whatever width you want */
}

.contact-container {
  height: 360px; /* or whatever height you want */
  display: flex;
  overflow: hidden;
}

.contact-container2 {  
  display: flex;
  overflow: hidden;
}

.about-background {
  background-image: url("./image/background1.png");
  background-size: "cover";
  height: 45vh;
  margin-top: -70px;
  font-size:50px;
  background-repeat:no-repeat; 
}
